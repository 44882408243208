import $ from 'jquery';
import ClipboardJS from 'clipboard';
export default class Modal {
  constructor() {
    this.modalClose()
    this.urlCopy()
    this.ieDisplay()
  }
  modalClose(){
    $('.jsc-modal-close').on('click',()=>{
      $('.jsc-modal').fadeOut(()=>{
        $('.jsc-substitute').removeClass('u-hidden')
      })
    })
  }
  urlCopy(){
    const clipboard = new ClipboardJS('.jsc-copy-btn');
    clipboard.on('success', (e)=>{
      e.trigger.classList.add('is-copy')
      e.trigger.firstElementChild.textContent="URLをコピーしました"
    })
  }
  ieDisplay(){
    const userAgent = window.navigator.userAgent.toLowerCase()
    console.log(userAgent)
    if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
      $('.jsc-modal').removeClass('u-hidden')
      $('.jsc-support').remove()
    }else{
      $('.jsc-substitute').remove()
    }
  }
}