import Home from '../projects/home'
import Slider from './Slider'
import Scroll from './Scroll'
import Faq from './Faq'
import Header from './Header'
import Iframe from './Iframe'
import Modal from './Modal'
export default class Router {
  constructor() {
    this.model = {
      pathName: location.pathname,
    }

    this.initRouting()
  }

  initRouting() {
    const pathName = this.model.pathName
    switch (pathName) {
      case '/':
        new Home()
        new Slider()
        new Modal()
        new Scroll()
        new Faq()
        new Header()
        break
      default:
        new Home()
        new Slider()
        new Modal()
        new Scroll()
        new Faq()
        new Header()
        new Iframe()
        break
    }
  }
}
