import $ from 'jquery';

export default class Header {
  constructor() {
    this.hoverAddColor()
    this.toggleSubMenu()
    this.toggleAccordion()
    this.toggleSpMenu()
  }
  //ページ内リンクスムーススクロール（Header分を加味した高さに）
  hoverAddColor(){
    const $header = $('#jsi-header')
    if(!($header.hasClass('is-coloring'))){
      $header.on({
        "mouseenter": (e)=>{
          const $headerPosition = $header.offset().top
          if($headerPosition === 0){
            $(e.currentTarget).addClass('is-coloring')
          }
        },
        "mouseleave": (e)=>{
          const $headerPosition = $header.offset().top
          if($headerPosition === 0){
            $(e.currentTarget).removeClass('is-coloring')
          }
        }
      })
    }
    $(window).on('scroll',()=>{
      const $headerPosition = $header.offset().top
      if($headerPosition > 0){
        $header.addClass('jsc-coloring')
      }else{
        $header.removeClass('jsc-coloring')
      }
    })
  }

  toggleSubMenu(){
    const $header = $('#jsi-header')
    const $subMenu = $('.jsc-sub-menu')
    const $subMenuActive = $('.jsc-sub-active')
    const $subMenuNav = $('.jsc-sub-nav')
    const $body = $('body')
    $subMenuActive.on({
      "mouseenter": (e)=>{
        $subMenu.removeClass('u-hidden')
        const activeId = $(e.currentTarget).attr('id')
        $subMenuNav.addClass('u-hidden')
        if(activeId === 'jsi-menu-services'){
          $('#jsi-sub-nav-services').removeClass('u-hidden')
          $body.addClass('u-filter-dark')
        }else if(activeId === 'jsi-menu-about'){
          $('#jsi-sub-nav-about').removeClass('u-hidden')
          $body.addClass('u-filter-dark')
        }
      },
      // "mouseleave": (e)=>{
      //   $subMenu.addClass('u-hidden');
      // }
    })
    if(!($subMenuActive.hasClass('u-hidden'))){
      $header.on({
        "mouseleave": (e)=>{
          $subMenu.addClass('u-hidden')
          $subMenuNav.addClass('u-hidden')
          $body.removeClass('u-filter-dark')
        }
      })
    }
  }

  toggleSpMenu(){
    const $toggleBtn = $('.jsc-hambuger')
    const $spMenu = $('.jsc-sp-menu')
    $toggleBtn.on('click',()=>{
      $spMenu.stop(false,false).slideToggle(200)
      $toggleBtn.stop(false,false).toggleClass('is-open')
    })
  }

  toggleAccordion(){
    const $accordionToggleBtn = $('.jsc-header-accordion__btn')
    $accordionToggleBtn.on('click',(e)=>{
      $(e.currentTarget).next().stop(false, false).slideToggle()
      $(e.currentTarget).stop(false, false).toggleClass('is-open')
    })
  }
}