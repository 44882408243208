// import $ from 'jquery';
export default class Home {
  // constructor() {
  //   this.modalClose()
  // }
  // modalClose(){
  //   $('.jsc-modal-close').on('click',()=>{
  //     $('.jsc-modal').fadeOut()
  //   })
  // }
}