import $ from 'jquery';
import 'slick-carousel';

export default class Slider {
  constructor() {
    this.caseSlider()
    this.articleSlider()
    this.fvSlider()
  }

  fvSlider(){
    let currentIndex = 0
    const $slide = $('.jsc-fv-item')
    //initialize
    $slide.css('display', 'none')
    $slide.eq(currentIndex).removeClass('u-hidden').css('display', 'block')
    const changeSlide = ()=>{
      if(currentIndex === 2){
        currentIndex = 0
        $slide.fadeOut(1500);
        $slide.eq(currentIndex).fadeIn(1500)
      } else{
        currentIndex++;
        $slide.fadeOut(1500);
        $slide.eq(currentIndex).fadeIn(1500)
      }
    }
    setInterval(changeSlide, 6000)
  }
  // caseセクションのスライダーのコントローラー
  caseSlider(){
    const $caseSlider = $('#jsi-case-slider')
    const $caseSliderBtn =  $('.jsc-case-slider-btn')
    const $slideStatus = $('#jsi-slider-status')
    //スライダーの設定
    $caseSlider.slick({
      infinite: true,
      slidesToShow: 4,
      prevArrow: '<a class="p-section__slider--prev" ></a>',
      nextArrow: '<a class="p-section__slider--next"></a>',
      responsive: [
        {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              centerMode: false,
              vertical: false
            }
        }
    ]
    }).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      $slideStatus.text(nextSlide + 1)
    })
    //next,prevボタンとslickのボタンを連動させる
    $caseSliderBtn.on('click',(e)=>{
      const slideControle = $(e.currentTarget).data('controle')
      const $targetAnchor = $(`.p-section__slider--${slideControle}`)
      $targetAnchor.trigger("click")
    })
  }

  articleSlider(){
    const $articleSlider = $('#jsi-article-slider')
    $articleSlider.slick({
      infinite: true,
      slidesToShow: 4,
      arrows: false,
      autoplay: true, 
      autoplaySpeed: 4000, 
      responsive: [
        {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              centerMode: false,
              vertical: false
            }
        }
    ]
    })
  }
}