import $ from 'jquery';

export default class Iframe {
  constructor() {
    this.iframeAdjust()
  }
  //ページ内リンクスムーススクロール（Header分を加味した高さに）
  iframeAdjust(){
    window.addEventListener('message', function(e) {
      const iframe = $("#jsi-iframe");
      const eventName = e.data[0];
      const data = e.data[1];
      switch(eventName) {
          case 'setHeight':
              iframe.height(data);
              break;
          }
      }, false);
  }
}