import $ from 'jquery';

export default class Scroll {
  constructor() {
    this.smoothScroll()
    this.blogIndexSticky()
  }
  //ページ内リンクスムーススクロール（Header分を加味した高さに）
  smoothScroll(){
    const $inPageLink = $('a[href^="#"]')
    $inPageLink.on('click',(e)=>{
      const targetId = $(e.currentTarget).attr('href')
      const adjustHeaderHeight = $('#jsi-header').innerHeight()
      const targetPosition = $(targetId).offset().top - adjustHeaderHeight
      const scrollSpeed = 400
      $('body,html').animate({scrollTop:targetPosition}, scrollSpeed, 'swing');
    })
  }

  blogIndexSticky(){
    if($(window).width() > 1339 && document.URL.match(/post_blog/)){
      const $container = $('#jsi-article-container')
      const $index = $('#jsi-blog-index')
      const containerHeadPosition = $container.offset().top
      const containerHeight = $container.innerHeight()
      const indexHeight = $index.innerHeight()

      console.log()
      $(window).on('scroll',()=>{
        const $header = $('#jsi-header')
        const $headerPosition = $header.offset().top + 120
        console.log($headerPosition)
        console.log(indexHeight)
        if($headerPosition > containerHeadPosition && $headerPosition < (containerHeight + containerHeadPosition - indexHeight)){
          $index.css({
            position:'fixed',
            top:'120px',
            right: 'calc((100% - 1170px) / 2)'
          })
        }else if($headerPosition < containerHeadPosition){
          $index.css({
            position:'absolute',
            top:'0',
            right: 'calc((100% - 1170px) / 2)'
          })
        }else if($headerPosition > (containerHeight + containerHeadPosition - indexHeight)){
          $index.css({
            position:'absolute',
            top: 'auto',
            bottom: '0',
            right: 'calc((100% - 1170px) / 2)'
          })
        }
      })
    }
  }
}