import $ from 'jquery';

export default class Faq {
  constructor() {
    this.faqAccordion()
  }
  //ページ内リンクスムーススクロール（Header分を加味した高さに）
  faqAccordion(){
    const $accordionBtn = $('.jsc-accordion')
    $accordionBtn.on('click',(e)=>{

      $(e.currentTarget).find('.jsc-accordion-answer').stop(false, false).slideToggle()
      $(e.currentTarget).find('.jsc-accordion-status').stop(false, false).toggleClass('is-open')
    })
  }
}